#contact {
  max-width: 100vw;
    // mobile
    @media only screen and (max-width: 700px) {
      background-color: #aed1cf;
      margin: 0;
      padding: 0;
      height: 50vh;
    }
    //desktop
    @media only screen and (min-width: 700px) {
      background-color: #aed1cf;
      margin: 0;
      padding: 0;
      height: 100vh;
      margin: 0;
      padding: 0;
      height: 90vh;
      overflow-y: hidden;
    }
}

.contacttextbody {
  //mobile
  @media only screen and (max-width: 700px) {
    max-width: 85%;
    padding-top: 5vh;
    margin: auto;
  }
  //web
  @media only screen and (min-width: 700px) {
    max-width: 65%;
    text-align: left;
    margin-left: 10vw;
    padding-top: 10vw;
  }
}

.linkWrapper {
    // mobile
    @media only screen and (max-width: 700px) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        width: 85vw;
        margin: auto;
    }
    //desktop
    @media only screen and (min-width: 700px) {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }
}

.linkWrapper > a {
    // mobile
    @media only screen and (max-width: 700px) {
        margin-top: 5vh;
        color: #c1f599;
        width: 50vw;
        text-align: center;
        font-weight: 600;
        font-size: 4vw;
        line-height: 4vh;
        height: 30px;
        text-transform: none !important;
        z-index: 5;
    }
    //desktop
    @media only screen and (min-width: 700px) {
        color: #c1f599;
        max-width: 24%;
        text-align: center;
        font-weight: 600;
        height: 30px;
        text-transform: none !important;
        z-index: 5;
    }
}

#contact h3 {
  font-family: lores-15-bold-alt-oakland, sans-serif;
  font-weight: 400;
  font-style: normal;
  //mobile
  @media only screen and (max-width: 700px) {
    font-size: 48pt;
    margin: 0;
    padding-bottom: 15pt;
    padding-top: 25pt;
    color: #376564;
    width: 100%;
    text-align: left;
  }
  //web
  @media only screen and (min-width: 700px) {
    font-size: 96pt;
    margin: 0;
    padding-bottom: 15pt;
    color: #376564;
    margin-top: 15vh;
  }
}

#socials {
    // mobile
    @media only screen and (max-width: 700px) {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-right: 0vw;
        align-items: center;
    }
    //desktop
    @media only screen and (min-width: 700px) {
        width: 40%;
        display: flex;
        justify-content: space-between;
        margin-right: 4vw;
        align-items: center;
    }
}

#socials .social {
    // mobile
    @media only screen and (max-width: 700px) {
      i {
        font-size: 32pt;
        transition: .25s ease;
        color: #376564;
    }
    }
    //desktop
    @media only screen and (min-width: 700px) {
      i {
        font-size: 32pt;
        transition: .25s ease;
        color: #376564;
        &:hover {
          font-size: 36pt;
          transition: .5s ease;
          color: #c1f599;
        }
    }
    }
    text-transform: none;
    text-align: center;
    z-index: 5;
}

#socialIcon{
    // mobile
    @media only screen and (max-width: 700px) {
    }
    //desktop
    @media only screen and (min-width: 700px) {
    }
    margin-top: 15px;
}

#contactdevice {
  // mobile
  @media only screen and (max-width: 700px) {
    display: none;
  }
  //desktop
  @media only screen and (min-width: 700px) {
    width: 40vw;
    float: right;
    position: absolute;
    margin-left: 50vw;
    overflow: hidden;
    height: 80vh;
      img {
        width: 100%;
        margin: auto;
      }
  }
}
