#about {
  background-color: #EEC25E;
  margin: 0;
  padding: 0;
  max-width: 100vw;
  @media only screen and (max-width: 700px) {
    min-height: 75vh;
}
  @media only screen and (min-width: 700px) {
    min-height: 55vh;

}
}

.abouthead {
  font-family: lores-15-bold-alt-oakland, sans-serif;
  font-weight: 400;
  font-style: normal;  //mobile
  @media only screen and (max-width: 700px) {
    font-size: 48pt;
    margin: 0;
    padding-top: 30pt;
    padding-bottom: 45pt;
    color: #294956;
  }
  //web
  @media only screen and (min-width: 700px) {
    font-size: 48pt;
    margin: 0;
    padding-bottom: 25pt;
    color: #294956;
  }
}

.aboutbody {
  //mobile
  @media only screen and (max-width: 700px) {
    font-size: 14pt;
    font-family: Obvia, sans-serif;
    margin: 0;
    text-align: justify;
    color: #294956;

  }
  //web
  @media only screen and (min-width: 700px) {
    font-size: 14pt;
    font-family: Obvia, sans-serif;
    margin: 0;
    text-align: left;
    color: #294956;
    max-width: 65%;

  }
}

.abouttextbody {
  //mobile
  @media only screen and (max-width: 700px) {
    max-width: 85%;
    margin-left: 5vw;
    padding-top: 5vh;
    padding-bottom: 3vh;


  }
  //web
  @media only screen and (min-width: 700px) {
    max-width: 50%;
    text-align: left;
    margin-left: 10vw;
    padding-top: 10vw;
    z-index: 4;
    position: relative;
    padding-bottom: 5vh;
  }
}
