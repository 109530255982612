#partner {
  margin: 0;
  padding: 0;
  padding-top: 10vh;
  max-width: 100vw;
  background-color: #294956;
  @media only screen and (max-width: 700px) {
    height: 225vh;
  }
  @media only screen and (min-width: 700px) {
    height: 90vh;
  }
}

.innerpartner {
  background-color: #F2DCC6;
  margin: 0;
  padding: 0;
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  @media only screen and (max-width: 700px) {
    height: 225vh;
  }
  @media only screen and (min-width: 700px) {
    height: 90vh;
  }
}

.partnerhead {
  font-family: lores-15-bold-alt-oakland, sans-serif;
  font-weight: 400;
  font-style: normal;
  //mobile
  @media only screen and (max-width: 700px) {
    font-size: 24pt;
    margin: auto;
    width: 100vw;
    padding-bottom: 15pt;
    color: #294956;
    text-align: center;
  }
  //web
  @media only screen and (min-width: 700px) {
    font-size: 36pt;
    margin: auto;
    width: 100%;
    padding-bottom: 15pt;
    color: #294956;
    text-align: center;
  }
}

.partnerbody {
  //mobile
  @media only screen and (max-width: 700px) {
    font-size: 14pt;
    font-family: Obvia, sans-serif;
    margin: 0;
    text-align: justify;
    color: #294956;

  }
  //web
  @media only screen and (min-width: 700px) {
    font-size: 14pt;
    font-family: Obvia, sans-serif;
    margin: 0;
    text-align: left;
    color: #294956;

  }
}

.partnertextbody {
  //mobile
  @media only screen and (max-width: 700px) {
    max-width: 100%;
    padding-top: 5vh;
    margin: 0 auto;
  }
  //web
  @media only screen and (min-width: 700px) {
    max-width: 65%;
    text-align: left;
    padding-top: 5vw;
    flex: 65;
    text-align: center;
  }
}

.thepartner {
  //mobile
  @media only screen and (max-width: 700px) {
    max-width: 85%;
    height: 50%;
    margin-top: 20vh;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    a {
      width: 300px;
      margin: 25px auto;
      div {
        margin: auto;
        width: 100%;
        height: 100%;
        img {
          max-width: 200px;
          width: 100%;
          margin: auto;
        }
      }
    }
  }
  //web
  @media only screen and (min-width: 700px) {
    margin: auto;
    margin-top: 5vh;
    margin-right: 5vw;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    a {
      width: 300px;
      flex: 20%;
      padding: 10px;
      padding-bottom: 25px;
      margin: auto;
      div {
        margin: auto;
        width: 100%;
        height: 100%;
        display: flex;
        align-content: center;
        img {
          width: 100%;
          max-width: 200px;
          margin: auto;
        }
      }
    }
  }
}

#partnerdevice {
  @media only screen and (max-width: 700px) {
    display: none;
}
  width: 50vw;
  flex: 50;
  height: 110vh;
  background-position: center;
  margin-top: -7%;
  background-repeat: no-repeat;
  background-size: contain;
}
