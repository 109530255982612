html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  font-family: lores-12, sans-serif;
  background-color: #AED1CF;
  font-style: normal;
  padding: 0;
  max-width: 100vw;
  overflow-x: hidden;
}

#navbar {
  position: fixed;
  z-index: 10;
  overflow: hidden;
  //mobile
  @media only screen and (max-width: 700px) {
    font-size: 18pt;
    display: fixed;
    max-height: 0%;
    margin-top: 55px;
    margin-left: 15px;
    border-radius: 10px;
    box-sizing: border-box;
    transition: .5s ease;
    background-color: transparentize(white, 0.2);
  }
  //web
  @media only screen and (min-width: 700px) {
    font-size: 36pt;
    display: flex;
    margin-top: 3vh;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .navtag {
    @media only screen and (max-width: 700px) {
      padding: 5px;
      cursor: pointer;
    }
    @media only screen and (min-width: 700px) {
      cursor: pointer;
      font-size: 18pt;
      font-weight: bold;
      margin-left: 5vw;
      text-decoration: none !important;
      a {
        text-decoration: none !important;
        color: #D6634C;
      }
    }
  }
  .homebutton {
    @media only screen and (max-width: 700px) {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 0% 0%;
      width: 40pt;
      height: 40pt;
    }
    @media only screen and (min-width: 700px) {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 0% 0%;
      width: 40pt;
      height: 40pt;
    }
  }
}

#hamburger {
  @media only screen and (max-width: 700px) {
    position: fixed;
    margin-left: 15px;
    margin-top: 15px;
    height: 25px;
    width: 25px;
    cursor: pointer;
    div {
      height: 20%;
      margin-bottom: 20%;
      background-color: white;
      border-radius: 3px;
    }
  }
  @media only screen and (min-width: 700px) {
    display: none;
  }
}
