
header {
  height: 100vh;
  margin-top: 0;

  @media only screen and (max-width: 700px) {
    background-size: 125%;
    background-repeat: no-repeat;
    background-color: #294956

}
  @media only screen and (min-width: 700px) {
  background-size: cover;
  background-repeat: no-repeat;
}
}

.screenhead {
  //mobile
  @media only screen and (max-width: 700px) {
    padding: 3vh;
    font-weight: 600;
    border-radius: 30px;
    font-size: 3vw;
    color: #304854;
    text-shadow: -3px 3px #85da7d;
    margin-bottom: 0;
  }
  //web
    @media only screen and (min-width: 700px) {
    padding: 3vh;
    padding-bottom: 1vh;
    font-weight: 600;
    border-radius: 30px;
    font-size: 18pt;
    color: #304854;
    text-shadow: -3px 3px #85da7d;
    margin-bottom: 0;
  }
}

.screenbody {
  //mobile
  @media only screen and (max-width: 700px) {
    font-weight: 200;
    font-size: 3vw;
    font-style: normal;
    max-width: 80%;
    text-shadow: -3px 3px #85da7d;
    margin: auto;
    color: #304854;
    padding-bottom: 10px;
  }
  //web
  @media only screen and (min-width: 700px) {
    font-weight: 200;
    font-size: 12pt;
    font-style: normal;
    max-width: 80%;
    text-shadow: -3px 3px #85da7d;
    margin: auto;
    color: #304854;
    padding-bottom: 10px;
  }
}

#console {
  z-index: 2;
  //mobile
  @media only screen and (max-width: 700px) {
    width: 90vw;
    height: 50vw;
    position: relative;
    top: 20vh;
    margin: auto;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
//web
  @media only screen and (min-width: 700px) {
    width: 800px;
    height: 476px;
    position: relative;
    top: 25vh;
    margin-left: 40vw;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}


#landinggroup {
  border-radius: 20px;
  height: 350px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  position: relative;
}


#textcluster {
  overflow: hidden;
  //mobile
  @media only screen and (max-width: 700px) {
    margin-left: 13vw;
    width: 65vw;
    position: relative;
    margin-right: auto;
    transform: rotate(15deg);
    margin-bottom: 25%;
  }
  //web
  @media only screen and (min-width: 700px) {
    position: relative;
    margin-left: 2.75vw;
    margin-right: auto;
    margin-bottom: 18%;
    width: 100%;
    //transform: rotate(15deg);
  }
}

#sprite {
  position: relative;
  margin-left: 15vw;
  margin-right: auto;
  margin-bottom: -10%;
  width: 150pt;
  height: 300pt;
  overflow: hidden;
  transform: rotate(15deg);
  > div {
    background-size: 200%;
    background-position: 0% 0%;
    background-repeat: no-repeat;
    width: 320pt;
    height: 320pt;
    animation: sprite 5s infinite;
  }
}

#sprite2 {
  //mobile
  @media only screen and (max-width: 700px) {
    display: none;
  }

  position: relative;
  margin-left: 275px;
  margin-right: auto;
  width: 75pt;
  height: 75pt;
  overflow: hidden;
  > div {
    background-size: 100%;
    background-position: 0% 0%;
    background-repeat: no-repeat;
    width: 320pt;
    height: 320pt;
    animation: sprite 5s infinite;
  }
}

#sprite3 {
  @media only screen and (max-width: 700px) {
    transform: rotate(15deg) scale(.65);
    position: relative;
    margin: auto;
    margin-bottom: 20%;
    width: 125pt;
    height: 125pt;
    overflow: hidden;
    > div {
      background-size: 100%;
      background-position: 0% 0%;
      background-repeat: no-repeat;
      width: 455pt;
      height: 455pt;
      animation: sprite 5s infinite;
    }
  }
  @media only screen and (min-width: 700px) {
  transform: rotate(15deg);
  position: relative;
  margin-left: 300px;
  margin-right: auto;
  margin-bottom: 100px;
  width: 125pt;
  height: 125pt;
  overflow: hidden;
  > div {
    background-size: 100%;
    background-position: 0% 0%;
    background-repeat: no-repeat;
    width: 455pt;
    height: 455pt;
    animation: sprite 5s infinite;
  }
}
}

.stutter {
  opacity: 0;
  animation: stutter 2s infinite;
}

#title {
  //mobile
  @media only screen and (max-width: 700px) {
    position: absolute;
    margin: auto;
    margin-top: 65vh;
    text-align: center;
    width: 100%;
    font-size: 32pt;
    font-weight: bold;
    font-family: lores-15-bold-alt-oakland, sans-serif;
    color: #304854;
    #eventdate {
      font-size: 18pt;
      font-family: obvia, sans-serif;
      text-align: center;
      font-weight: 500;
    }
    #subtitle {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr; /* 3 columns */
      grid-template-rows: auto auto; /* 2 rows */
      gap: 10px;
      font-size: 18pt;
      font-family: obvia, sans-serif;
      font-weight: 700;
      margin: auto;
      margin-top: 5vh;
      max-width: 90vw;
      div {
        max-width: 40vw;
        margin: auto;
        margin-bottom: 1vh;
        padding: 5px;
        a {
          text-decoration: none;
          color: #294956;
        }
      }
      > :nth-child(1) {
        background-color: #F58E75;
        border-radius: 15px;
      }
      > :nth-child(2) {
        background-color: #ff9c08;
        border-radius: 15px;
      }
      > :nth-child(3) {
        background-color: #fee28a;
        border-radius: 15px;
      }
    }
  }
  //web
  @media only screen and (min-width: 700px) {
  position: absolute;
  margin-top: 35vh;
  margin-left: 10vw;
  font-size: 48pt;
  font-weight: bold;
  font-family: lores-12, sans-serif;
  color: #304854;
  >div:nth-child(1) {
    font-family: lores-15-bold-alt-oakland, sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  #eventdate {
    font-size: 24pt;
    font-family: obvia, sans-serif;
    text-align: center;
    font-weight: 500;
  }
  #subtitle {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr; /* 3 columns */
      grid-template-rows: auto auto; /* 2 rows */
      gap: 10px;
      transform: none;
      font-size: 14pt;
      margin-top: 5vh;
      font-weight: 700;
      font-family: obvia, sans-serif;
      width: 25vw;
      justify-content: space-between;
      div {
        margin-bottom: 1vh;
        padding: 10px;
        transition: .25s;
        &:hover {
          transform: scale(1.025);
          transition: .5s;
        }
        a {
          text-decoration: none;
          color: #294956;
        }
      }
      > :nth-child(1) {
        background-color: #F58E75;
        border-radius: 15px;
      }
      > :nth-child(2) {
        background-color: #ff9c08;
        border-radius: 15px;
      }
      > :nth-child(3) {
        background-color: #fee28a;
        border-radius: 15px;
      }

  }
}
}

.subtitle-hacker {
  grid-column: 1 / span 3; /* Span across all 3 columns */
  margin-bottom: 10px;
}

.subtitle-genaiworkshop {
  grid-column: 1 / span 3; /* Span across all 3 columns */
  margin-bottom: 10px;
}

.subtitle-mentor, .subtitle-workshop, .subtitle-partner {
  grid-column: auto; /* Each takes one column */
}

@keyframes stutter {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 1;
  }
  94% {
    opacity: 1;
  }
  95% {
    opacity: 0;
  }
}
